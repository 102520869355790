import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
    // Notification
    setNotification: ['message', 'level'],
    clearNotification: null,
    // Auth & User
    loginAttempt: ['user'],
    loginAuthSuccess: ['auth', 'success'],
    loginUserSuccess: ['user', 'success'],
    loginFailure: ['error'],
    logout: null,
    forgotAttempt: ['email'],
    forgotSuccess: ['data', 'success'],
    forgotFailed: ['error'],
    resetAttempt: ['data'],
    resetSuccess: ['data', 'success'],
    resetFailed: ['error'],
    getUsersAttempt: null,
    // getUsersSuccess: ['data'],
    getUsersFailure: null,
    registerAttempt: ['user'],
    registerSuccess: ['user', 'success'],
    registerFailure: ['error'],
    setSubscriptionType: ['data'],
    setAppointment: ['value'],
    bookingSuccess: ['booking', 'success'],
    bookingFailure: ['error'],
    getAppointmentRequest: ['data'],
    getAppointmentSuccess: ['appointments', 'success'],
    getAppointmentFailed: ['error'],
    updateAppointmentStatusRequest: ['data'],
    updateAppointmentStatusSuccess: ['appointment', 'success'],
    updateAppointmentStatusFailed: ['error'],
    getUsersRequest: ['data'],
    getUsersSuccess: ['users', 'success'],
    getUsersFailed: ['error'],
    getUserInfoRequest: ['userId'],
    getUserInfoSuccess: ['user', 'success'],
    getUserInfoFailed: ['error'],
    getUserDetailInfoRequest: ['userId'],
    getUserDetailInfoSuccess: ['user', 'success'],
    getUserDetailInfoFailed: ['error'],
    updateUserInfoRequest: ['data', 'userId'],
    updateUserInfoSuccess: ['user', 'success'],
    updateUserInfoFailed: ['error'],
    pickDate: ['data'],
    getBookingDatesRequest: null,
    getBookingDatesSuccess: ['bookings'],
    getBookingDatesFailed: ['error'],
    confirmAttempt: ['token'],
    confirmSuccess: ['data', 'success'],
    confirmFailed: ['error'],
    updateUserProfileRequest: ['data', 'userId'],
    updateUserProfileSuccess: ['user', 'success'],
    updateUserProfileFailed: ['error'],
    cancelAppointmentRequest: ['id'],
    cancelAppointmentSuccess: ['data', 'success'],
    cancelAppointmentFailed: ['error'],
    setReschedule: null,
    sendEmailRequest: ['data'],
    sendEmailSuccess: ['data', 'success'],
    sendEmailFailed: ['error'],
    confirmRegisterAttempt: ['token'],
    confirmRegisterSuccess: ['data', 'success'],
    confirmRegisterFailed: ['error'],
    removeUserAppointment: null,
    setUserAppointment: ['booking', 'success'],
    // getUserInfoSuccess: ['user']
    getPlacesRequest: ['data'],
    getPlacesSuccess: ['places', 'success'],
    getPlacesFailed: ['error'],
    createPlaceRequest: ['value'],
    createPlaceSuccess: ['data', 'success'],
    createPlaceFailed: ['error'],
    removePlaceRequest: ['data'],
    removePlaceSuccess: ['place', 'success'],
    removePlaceFailed: ['error'],
    enablePlaceRequest: ['data'],
    enablePlaceSuccess: ['place', 'success'],
    enablePlaceFailed: ['error'],
    disablePlaceRequest: ['data'],
    disablePlaceSuccess: ['place', 'success'],
    disablePlaceFailed: ['error'],
    getTimeslotsRequest: ['data'],
    getTimeslotsSuccess: ['timeslots', 'success'],
    getTimeslotsFailed: ['error'],
    createTimeslotRequest: ['value'],
    createTimeslotSuccess: ['data', 'success'],
    createTimeslotFailed: ['error'],
    removeTimeslotRequest: ['data'],
    removeTimeslotSuccess: ['timeslot', 'success'],
    removeTimeslotFailed: ['error'],
    enableTimeslotRequest: ['data'],
    enableTimeslotSuccess: ['timeslot', 'success'],
    enableTimeslotFailed: ['error'],
    disableTimeslotRequest: ['data'],
    disableTimeslotSuccess: ['timeslot', 'success'],
    disableTimeslotFailed: ['error'],
});
