import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push, replace } from 'react-router-redux';
import { bindActionCreators, compose } from 'redux';
// import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

// import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
// import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
// import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';

import { Creators as Actions } from '../../actions';
import AdminTableHead from '../../components/admin/TableHeader';

const styles = (theme) => ({
    formControl: {
        margin: '10px 0',
        width: '100%',
    },
    formLabel: {
        color: '#717171 !important',
    },
    formInput: {
        color: '#717171',
        '&:before': {
            borderColor: '#717171',
        },
        '&:after': {
            borderColor: '#717171',
        },
    },
    formError: {
        color: 'red',
    },
    table: {
        minWidth: 520,
    },
    tableWrapper: {
        marginTop: 45,
        overflowX: 'auto',
    },
    progress: {
        margin: theme.spacing.unit * 2,
    },
    button: {
        margin: theme.spacing.unit,
    },
    addressOne: {
        fontWeight: 'bold',
        lineHeight: 1.5,
        fontFamily: 'Raleway Regular',
    },
    addressTwo: {
        fontFamily: 'Raleway Regular',
    },
});

const daysFromMonth = {
    Jan: 31,
    Feb: 28,
    Mar: 31,
    Apr: 30,
    May: 31,
    Jun: 30,
    Jul: 31,
    Aug: 31,
    Sep: 30,
    Oct: 31,
    Nov: 30,
    Dec: 31,
};

class AdminTimeslots extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            month: '',
            day: '',
            time: '',

            order: 'asc',
            orderBy: 'date',

            selected: [],
            page: 0,
            rowsPerPage: 10,
            open: false,
            rows: [
                { id: 'date', name: 'date', label: 'Date' },
                { id: 'time', label: 'Time', sorting: false },
                { id: 'active', name: 'active', label: 'Status' },
                { id: 'action', label: 'Action', sorting: false },
            ],
        };
    }

    componentWillMount() {
        this.props.getTimeslotsRequest();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.timeslot.isFetched) {
            this.setState({ data: nextProps.timeslot.timeslots });
        }
    }

    handleRequestSort = (event, property, sortBy, row) => {
        this.props.getTimeslotsRequest({
            field: row.name,
            sort_by: sortBy,
        });

        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        this.setState({ order, orderBy });
    };

    handleValueChange = (name) => (event) => {
        this.setState({
            [name]: event.target.value,
        });
    };

    handleChange = (row) => (event) => {
        switch (event.target.value) {
            case 'remove':
                this.props.removeTimeslotRequest({
                    id: row._id,
                });
                break;
            case 'enable':
                this.props.enableTimeslotRequest({
                    id: row._id,
                });
                break;
            case 'disable':
                this.props.disableTimeslotRequest({
                    id: row._id,
                });
                break;
            default:
                break;
        }
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value });
    };

    handleClickSave = () => {
        const { month = '', day = '', time = '' } = this.state;
        console.log(month, day, time);
        let date = '';
        if (month && day) {
            date = `${month} ${`0${day}`.slice(-2)}`;
        }
        if (date || time) {
            this.props.createTimeslotRequest({
                date,
                time,
            });
            this.setState({
                month: '',
                day: '',
                time: '',
            });
        }
    };

    goToDetailPage = (user) => {
        this.props.push(`/admin/user/${user._id}`);
    };

    render() {
        const { classes, timeslot } = this.props;
        const { data, order, orderBy, selected, rowsPerPage, page, rows, month, day, time } = this.state;

        if (timeslot.isFetched) {
            return (
                <>
                    <div>
                        <p>New Timeslot</p>
                        <FormControl className={classes.formControl} aria-describedby="month-helper-text">
                            <InputLabel>Month</InputLabel>
                            <Select
                                native
                                value={month}
                                onChange={this.handleValueChange('month')}
                                inputProps={{
                                    name: 'month',
                                    id: 'month-native-simple',
                                }}
                            >
                                <option value="" />
                                <option value="Jan">January</option>
                                <option value="Feb">February</option>
                                <option value="Mar">March</option>
                                <option value="Apr">April</option>
                                <option value="May">May</option>
                                <option value="Jun">June</option>
                                <option value="Jul">July</option>
                                <option value="Aug">August</option>
                                <option value="Sep">September</option>
                                <option value="Oct">October</option>
                                <option value="Nov">November</option>
                                <option value="Dec">December</option>
                            </Select>
                        </FormControl>
                        <FormControl className={classes.formControl} aria-describedby="day-helper-text">
                            <InputLabel>Day</InputLabel>
                            <Select
                                native
                                value={day}
                                onChange={this.handleValueChange('day')}
                                inputProps={{
                                    name: 'day',
                                    id: 'day-native-simple',
                                }}
                            >
                                <option value="" />
                                {[...new Array(daysFromMonth[month] || 0)].map((v, i) => (
                                    <option key={i} value={i + 1}>
                                        {i + 1}
                                    </option>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl className={classes.formControl} aria-describedby="time-helper-text">
                            <InputLabel>Timeslot</InputLabel>
                            <Select
                                native
                                value={time}
                                onChange={this.handleValueChange('time')}
                                inputProps={{
                                    name: 'time',
                                    id: 'time-native-simple',
                                }}
                            >
                                <option value="" />
                                <optgroup label="Morning">
                                    <option value="9 : 00">9 : 00</option>
                                    <option value="9 : 30">9 : 30</option>
                                    <option value="10 : 00">10 : 00</option>
                                    <option value="10 : 30">10 : 30</option>
                                    <option value="11 : 00">11 : 00</option>
                                    <option value="11 : 30">11 : 30</option>
                                </optgroup>
                                <optgroup label="Afternoon">
                                    <option value="13 : 00">13 : 00</option>
                                    <option value="13 : 30">13 : 30</option>
                                    <option value="14 : 00">14 : 00</option>
                                    <option value="14 : 30">14 : 30</option>
                                    <option value="15 : 00">15 : 00</option>
                                    <option value="15 : 30">15 : 30</option>
                                </optgroup>
                                <optgroup label="Evening">
                                    <option value="17 : 00">17 : 00</option>
                                    <option value="17 : 30">17 : 30</option>
                                    <option value="18 : 00">18 : 00</option>
                                    <option value="18 : 30">18 : 30</option>
                                    <option value="19 : 00">19 : 00</option>
                                    <option value="19 : 30">19 : 30</option>
                                </optgroup>
                            </Select>
                        </FormControl>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            onClick={() => this.handleClickSave()}
                        >
                            Save
                        </Button>
                    </div>

                    <Paper>
                        <div className={classes.tableWrapper}>
                            <Table className={classes.table} aria-labelledby="tableTitle">
                                <AdminTableHead
                                    numSelected={selected.length}
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={this.handleRequestSort}
                                    rowCount={data.length}
                                    rows={rows}
                                />
                                <TableBody>
                                    {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((n) => (
                                        <TableRow hover tabIndex={-1} key={n._id}>
                                            <TableCell>{n.date}</TableCell>
                                            <TableCell>{n.time}</TableCell>
                                            <TableCell>{n.active ? 'Active' : 'Inactive'}</TableCell>
                                            <TableCell>
                                                <Select
                                                    native
                                                    onChange={this.handleChange(n)}
                                                    inputProps={{
                                                        name: 'actionType',
                                                        id: 'actionType-native-simple',
                                                    }}
                                                >
                                                    <option value="">Select Action</option>
                                                    <option value="enable">Enable Timeslot</option>
                                                    <option value="disable">Disable Timeslot</option>
                                                    <option value="remove">Remove Timeslot</option>
                                                </Select>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                        <TablePagination
                            component="div"
                            count={data.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            backIconButtonProps={{
                                'aria-label': 'Previous Page',
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'Next Page',
                            }}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                    </Paper>
                </>
            );
        }
        return <CircularProgress className={classes.progress} />;
    }
}

AdminTimeslots.propTypes = {
    classes: PropTypes.object.isRequired,
};

const {
    getTimeslotsRequest,
    createTimeslotRequest,
    removeTimeslotRequest,
    enableTimeslotRequest,
    disableTimeslotRequest,
} = Actions;

const mapStateToProps = (state) => ({
    timeslot: state.timeslot,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            push,
            replace,
            getTimeslotsRequest,
            createTimeslotRequest,
            removeTimeslotRequest,
            enableTimeslotRequest,
            disableTimeslotRequest,
        },
        dispatch
    );

export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(AdminTimeslots);
