import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push, replace } from 'react-router-redux';
import { bindActionCreators, compose } from 'redux';
// import moment from 'moment';

import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconError from '@material-ui/icons/Error';
import IconButton from '@material-ui/core/IconButton';
import IconClose from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import moment from 'moment';
import { Creators as Actions } from '../../actions';
import { findMonth } from '../../util/helpers';
// import glass from '../../assets/glass.png'
// import { NONE } from 'apisauce';

const styles = (theme) => ({
    paper: {
        padding: 15,
    },
    label: {
        fontSize: 16,
        fontWeight: 'bold',
        marginRight: 15,
    },
    formControl: {
        margin: '10px 0',
        width: '100%',
    },
    formLabel: {
        color: '#717171 !important',
    },
    formInput: {
        color: '#717171',
        '&:before': {
            borderColor: '#717171',
        },
        '&:after': {
            borderColor: '#717171',
        },
    },
    formError: {
        color: 'red',
    },
    grid: {
        backgroundColor: '#ffffff',
    },
    gridx: {
        padding: '25px',
        [theme.breakpoints.down('md')]: {
            padding: '10px',
        },
    },
    gridImage: {
        padding: '25px',
        [theme.breakpoints.down('md')]: {
            padding: '10px',
        },
        position: 'relative',
    },
    img: {
        width: '90%',
        maxWidth: 250,
        height: 250,
    },
    tables: {
        width: 600,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    tableIn: {
        padding: 20,
        width: '100%',
        backgroundColor: '#ffffff',
        [theme.breakpoints.down('xs')]: {
            padding: 5,
        },
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
    },
    td: {
        textAlign: 'center',
        verticalAlign: 'middle',
        padding: 5,
        fontSize: 15,
        [theme.breakpoints.down('xs')]: {
            padding: '10px 0',
            fontSize: 10,
        },
    },
    in: {
        padding: '50px 30px',
    },
    h1: {
        width: '100%',
        fontSize: 30,
    },
    p1: {
        paddingTop: 30,
        width: '100%',
        fontSize: 15,
        lineHeight: 1.5,
    },
    button: {
        margin: theme.spacing.unit,
    },
    input: {
        display: 'none',
    },
    file: {
        background: 'red',
        position: 'absolute',
        left: 30,
        height: 250,
        opacity: 0,
        zIndex: 99,
    },
    altCommentHidden: {
        display: 'none',
    },
    altComment: {
        width: 250,
        height: 250,
        position: 'absolute',
        top: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 24,
        border: '1px solid #dddddd',
        borderRadius: 5,
        zIndex: 1,
    },
    snackbar: {
        flexWrap: 'nowrap',
    },
    notification: {
        display: 'flex',
        alignItems: 'center',
    },
    iconError: {
        marginRight: theme.spacing.unit,
    },
    textField: {
        // marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200,
    },
});

class UserDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: '',
            // doctorNameError: false,
            lens: '',
            frames: '',
            warranty: '',
            glassRightSphere: '',
            glassRightCylinder: '',
            glassRightAxis: '',
            glassRightBase: '',
            glassRightAdd: '',
            glassRightDia: '',
            glassLeftSphere: '',
            glassLeftCylinder: '',
            glassLeftAxis: '',
            glassLeftBase: '',
            glassLeftAdd: '',
            glassLeftDia: '',
            contactRightSphere: '',
            contactRightCylinder: '',
            contactRightAxis: '',
            contactRightBase: '',
            contactRightAdd: '',
            contactRightDia: '',
            contactLeftSphere: '',
            contactLeftCylinder: '',
            contactLeftAxis: '',
            contactLeftBase: '',
            contactLeftAdd: '',
            contactLeftDia: '',
            visionTitle: '',
            visionContent: '',
            doctorName: '',
            selectedFile: null,
            notify: false,
            open: false,
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            dob: '',
            expiration_date: '',
            comments: '',
            modalOpen: false,
            openSnackbar: false,
            // licenseError: false,
        };
        this.handleselectedFile = this.handleselectedFile.bind(this);
    }

    componentWillMount() {
        const { userId } = this.props.match.params;
        this.setState({ userId });
        this.props.getUserDetailInfoRequest(userId);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.user.isFetched) {
            this.setState({ ...nextProps.user.userInfo }, () => {
                delete this.state.password;
                this.setState(this.state);

                if (this.state.dob) {
                    this.setState({ dob: moment(this.state.dob).format('YYYY-MM-DD') });
                }

                if (this.state.expiration_date) {
                    this.setState({ expiration_date: moment(this.state.expiration_date).format('YYYY-MM-DD') });
                }
            });

            this.setState({ notify: true });
        }
    }

    handleChange = (name) => (event) => {
        const validate = {};

        // switch (name) {
        //     case 'doctorName':
        //         validate.doctorNameError = !(event.target.value !== '');
        //         break;
        //     case 'license':
        //         validate.licenseError = !(event.target.value !== '');
        //         break;
        //
        //     default:
        //         break;
        // }

        this.setState({
            ...validate,
            [name]: event.target.value,
        });
    };

    gotoBack = () => {
        this.props.push('/admin/user');
    };

    handleSubmit = () => {
        this.setState({ modalOpen: false });

        const data = new FormData();

        const keys = Object.keys(this.state);
        keys.forEach((key) => {
            if (
                key === 'selectedFile' ||
                key === 'glassUrl' ||
                key === '_id' ||
                key === '__v' ||
                key === 'userId' ||
                key === 'notify' ||
                key === 'open'
            ) {
            } else {
                data.append(key, this.state[key]);
            }
        });
        if (this.state.selectedFile) {
            data.append('photo', this.state.selectedFile, this.state.selectedFile.name);
        }

        this.props.updateUserInfoRequest(data, this.state.userId);

        this.setState({ openSnackbar: true });
    };

    handleselectedFile = (event) => {
        const selectedFile = event.target.files[0];

        this.setState({ selectedFile });
        const reader = new FileReader();
        // var url =
        reader.readAsDataURL(selectedFile);

        reader.onloadend = function (e) {
            this.setState({
                glassUrl: [reader.result],
            });
        }.bind(this);
    };

    closeNotification = () => {
        // this.setState({
        //     notify: false,
        // });
        this.setState({ openSnackbar: false });
    };

    handleClickOpen = () => {
        this.handleSubmit();
    };

    handleClose = () => {
        this.setState({ modalOpen: false });
    };

    render() {
        const { classes, user, clearNotification, notification } = this.props;
        const { doctorNameError, licenseError } = this.state;

        if (user.isFetched && user.userInfo) {
            return (
                <>
                    <Paper className={classes.paper}>
                        <h3>User info</h3>
                        <FormControl className={classes.formControl} aria-describedby="firstName-helper-text">
                            <InputLabel htmlFor="firstName-helper" className={classes.formLabel}>
                                First Name
                            </InputLabel>
                            <Input
                                id="firstName-helper"
                                className={classes.formInput}
                                value={this.state.firstName}
                                defaultValue={this.state.firstName}
                                type="text"
                                onChange={this.handleChange('firstName')}
                            />
                        </FormControl>
                        <FormControl className={classes.formControl} aria-describedby="lastName-helper-text">
                            <InputLabel htmlFor="lastName-helper" className={classes.formLabel}>
                                Last Name
                            </InputLabel>
                            <Input
                                id="lastName-helper"
                                className={classes.formInput}
                                value={this.state.lastName}
                                defaultValue={this.state.lastName}
                                type="text"
                                onChange={this.handleChange('lastName')}
                            />
                        </FormControl>
                        <FormControl className={classes.formControl} aria-describedby="email-helper-text">
                            <InputLabel htmlFor="email-helper" className={classes.formLabel}>
                                Email
                            </InputLabel>
                            <Input
                                id="email-helper"
                                className={classes.formInput}
                                readOnly
                                value={this.state.email}
                                defaultValue={this.state.email}
                                type="text"
                                onChange={this.handleChange('email')}
                            />
                        </FormControl>
                        <FormControl className={classes.formControl} aria-describedby="dob-helper-text">
                            {/*
                <InputLabel htmlFor="dob-helper" className={classes.formLabel}>Birthday</InputLabel>
                <Input id="dob-helper" className={classes.formInput} value={(this.state.dob && moment(this.state.dob).format('YYYY-MM-DD')) || ''} defaultValue={(this.state.dob && moment(this.state.dob).format('YYYY-MM-DD')) || ''} type="date" onChange={this.handleChange('dob')} />
              */}
                            <TextField
                                id="dob-helper"
                                type="date"
                                className={classes.textField}
                                value={this.state.dob}
                                label="Birthday"
                                InputLabelProps={{ shrink: true }}
                                onChange={this.handleChange('dob')}
                            />
                        </FormControl>
                        <FormControl className={classes.formControl} aria-describedby="lens-helper-text">
                            <InputLabel htmlFor="phone-helper" className={classes.formLabel}>
                                Phone
                            </InputLabel>
                            <Input
                                id="phone-helper"
                                className={classes.formInput}
                                value={this.state.phone}
                                defaultValue={this.state.phone}
                                type="text"
                                onChange={this.handleChange('phone')}
                            />
                        </FormControl>
                        <Divider />
                        <h3>Glasses</h3>
                        <Grid container className={classes.grid} justify="center">
                            <Grid item xs={12} md={6} className={classes.gridImage}>
                                <img className={classes.img} src={this.state.glassUrl} alt="" />
                                <input type="file" onChange={this.handleselectedFile} className={classes.file} />
                                <p className={this.state.glassUrl ? classes.altCommentHidden : classes.altComment}>
                                    Please upload file
                                </p>
                            </Grid>
                            <Grid item xs={12} md={6} className={classes.gridx}>
                                <FormControl className={classes.formControl} aria-describedby="lens-helper-text">
                                    <InputLabel htmlFor="lens-helper" className={classes.formLabel}>
                                        LENS
                                    </InputLabel>
                                    <Input
                                        id="lens-helper"
                                        className={classes.formInput}
                                        value={this.state.lens}
                                        defaultValue={this.state.lens}
                                        type="text"
                                        onChange={this.handleChange('lens')}
                                    />
                                </FormControl>
                                <FormControl className={classes.formControl} aria-describedby="frames-helper-text">
                                    <InputLabel htmlFor="frames-helper" className={classes.formLabel}>
                                        FRAMES
                                    </InputLabel>
                                    <Input
                                        id="frames-helper"
                                        className={classes.formInput}
                                        value={this.state.frames}
                                        type="text"
                                        onChange={this.handleChange('frames')}
                                    />
                                </FormControl>
                                <FormControl className={classes.formControl} aria-describedby="warranty-helper-text">
                                    <InputLabel htmlFor="warranty-helper" className={classes.formLabel}>
                                        WARRANTY
                                    </InputLabel>
                                    <Input
                                        id="warranty-helper"
                                        className={classes.formInput}
                                        value={this.state.warranty}
                                        type="text"
                                        onChange={this.handleChange('warranty')}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Divider />
                        <h3>Prescription</h3>
                        <h5>Glasses</h5>
                        <div className={classes.tableIn}>
                            <table className={classes.table}>
                                <tbody>
                                    <tr>
                                        <td className={classes.td} />
                                        <td className={classes.td}>SPHERE</td>
                                        <td className={classes.td}>CYLINDER</td>
                                        <td className={classes.td}>AXIS</td>
                                        <td className={classes.td}>BASE</td>
                                        <td className={classes.td}>ADD.</td>
                                        <td className={classes.td}>DIA</td>
                                    </tr>
                                    <tr>
                                        <td className={classes.td}>RIGHT O.D.</td>
                                        <td className={classes.td}>
                                            <FormControl
                                                className={classes.formControl}
                                                aria-describedby="glassRightSphere-helper-text"
                                            >
                                                <InputLabel
                                                    htmlFor="glassRightSphere-helper"
                                                    className={classes.formLabel}
                                                />
                                                <Input
                                                    id="glassRightSphere-helper"
                                                    className={classes.formInput}
                                                    value={this.state.glassRightSphere}
                                                    type="text"
                                                    onChange={this.handleChange('glassRightSphere')}
                                                />
                                            </FormControl>
                                        </td>
                                        <td className={classes.td}>
                                            <FormControl
                                                className={classes.formControl}
                                                aria-describedby="glassRightCylinder-helper-text"
                                            >
                                                <InputLabel
                                                    htmlFor="glassRightCylinder-helper"
                                                    className={classes.formLabel}
                                                />
                                                <Input
                                                    id="glassRightCylinder-helper"
                                                    className={classes.formInput}
                                                    value={this.state.glassRightCylinder}
                                                    type="text"
                                                    onChange={this.handleChange('glassRightCylinder')}
                                                />
                                            </FormControl>
                                        </td>
                                        <td className={classes.td}>
                                            <FormControl
                                                className={classes.formControl}
                                                aria-describedby="glassRightAxis-helper-text"
                                            >
                                                <InputLabel
                                                    htmlFor="glassRightAxis-helper"
                                                    className={classes.formLabel}
                                                />
                                                <Input
                                                    id="glassRightAxis-helper"
                                                    className={classes.formInput}
                                                    value={this.state.glassRightAxis}
                                                    type="text"
                                                    onChange={this.handleChange('glassRightAxis')}
                                                />
                                            </FormControl>
                                        </td>
                                        <td className={classes.td}>
                                            <FormControl
                                                className={classes.formControl}
                                                aria-describedby="glassRightBase-helper-text"
                                            >
                                                <InputLabel
                                                    htmlFor="glassRightBase-helper"
                                                    className={classes.formLabel}
                                                />
                                                <Input
                                                    id="glassRightBase-helper"
                                                    className={classes.formInput}
                                                    value={this.state.glassRightBase}
                                                    type="text"
                                                    onChange={this.handleChange('glassRightBase')}
                                                />
                                            </FormControl>
                                        </td>
                                        <td className={classes.td}>
                                            <FormControl
                                                className={classes.formControl}
                                                aria-describedby="glassRightAdd-helper-text"
                                            >
                                                <InputLabel
                                                    htmlFor="glassRightAdd-helper"
                                                    className={classes.formLabel}
                                                />
                                                <Input
                                                    id="glassRightAdd-helper"
                                                    className={classes.formInput}
                                                    value={this.state.glassRightAdd}
                                                    type="text"
                                                    onChange={this.handleChange('glassRightAdd')}
                                                />
                                            </FormControl>
                                        </td>
                                        <td className={classes.td}>
                                            <FormControl
                                                className={classes.formControl}
                                                aria-describedby="glassRightDia-helper-text"
                                            >
                                                <InputLabel
                                                    htmlFor="glassRightDia-helper"
                                                    className={classes.formLabel}
                                                />
                                                <Input
                                                    id="glassRightDia-helper"
                                                    className={classes.formInput}
                                                    value={this.state.glassRightDia}
                                                    type="text"
                                                    onChange={this.handleChange('glassRightDia')}
                                                />
                                            </FormControl>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={classes.td}>LEFT O.S.</td>
                                        <td className={classes.td}>
                                            <FormControl
                                                className={classes.formControl}
                                                aria-describedby="glassLeftSphere-helper-text"
                                            >
                                                <InputLabel
                                                    htmlFor="glassLeftSphere-helper"
                                                    className={classes.formLabel}
                                                />
                                                <Input
                                                    id="glassLeftSphere-helper"
                                                    className={classes.formInput}
                                                    value={this.state.glassLeftSphere}
                                                    type="text"
                                                    onChange={this.handleChange('glassLeftSphere')}
                                                />
                                            </FormControl>
                                        </td>
                                        <td className={classes.td}>
                                            <FormControl
                                                className={classes.formControl}
                                                aria-describedby="glassLeftCylinder-helper-text"
                                            >
                                                <InputLabel
                                                    htmlFor="glassLeftCylinder-helper"
                                                    className={classes.formLabel}
                                                />
                                                <Input
                                                    id="glassLeftCylinder-helper"
                                                    className={classes.formInput}
                                                    value={this.state.glassLeftCylinder}
                                                    type="text"
                                                    onChange={this.handleChange('glassLeftCylinder')}
                                                />
                                            </FormControl>
                                        </td>
                                        <td className={classes.td}>
                                            <FormControl
                                                className={classes.formControl}
                                                aria-describedby="glassLeftAxis-helper-text"
                                            >
                                                <InputLabel
                                                    htmlFor="glassLeftAxis-helper"
                                                    className={classes.formLabel}
                                                />
                                                <Input
                                                    id="glassLeftAxis-helper"
                                                    className={classes.formInput}
                                                    value={this.state.glassLeftAxis}
                                                    type="text"
                                                    onChange={this.handleChange('glassLeftAxis')}
                                                />
                                            </FormControl>
                                        </td>
                                        <td className={classes.td}>
                                            <FormControl
                                                className={classes.formControl}
                                                aria-describedby="glassLeftBase-helper-text"
                                            >
                                                <InputLabel
                                                    htmlFor="glassLeftBase-helper"
                                                    className={classes.formLabel}
                                                />
                                                <Input
                                                    id="glassLeftBase-helper"
                                                    className={classes.formInput}
                                                    value={this.state.glassLeftBase}
                                                    type="text"
                                                    onChange={this.handleChange('glassLeftBase')}
                                                />
                                            </FormControl>
                                        </td>
                                        <td className={classes.td}>
                                            <FormControl
                                                className={classes.formControl}
                                                aria-describedby="glassLeftAdd-helper-text"
                                            >
                                                <InputLabel
                                                    htmlFor="glassLeftAdd-helper"
                                                    className={classes.formLabel}
                                                />
                                                <Input
                                                    id="glassLeftAdd-helper"
                                                    className={classes.formInput}
                                                    value={this.state.glassLeftAdd}
                                                    type="text"
                                                    onChange={this.handleChange('glassLeftAdd')}
                                                />
                                            </FormControl>
                                        </td>
                                        <td className={classes.td}>
                                            <FormControl
                                                className={classes.formControl}
                                                aria-describedby="glassLeftDia-helper-text"
                                            >
                                                <InputLabel
                                                    htmlFor="glassLeftDia-helper"
                                                    className={classes.formLabel}
                                                />
                                                <Input
                                                    id="glassLeftDia-helper"
                                                    className={classes.formInput}
                                                    value={this.state.glassLeftDia}
                                                    type="text"
                                                    onChange={this.handleChange('glassLeftDia')}
                                                />
                                            </FormControl>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <h5>Contact Lens</h5>
                        <div className={classes.tableIn}>
                            <table className={classes.table}>
                                <tbody>
                                    <tr>
                                        <td className={classes.td} />
                                        <td className={classes.td}>SPHERE</td>
                                        <td className={classes.td}>CYLINDER</td>
                                        <td className={classes.td}>AXIS</td>
                                        <td className={classes.td}>BASE</td>
                                        <td className={classes.td}>ADD.</td>
                                        <td className={classes.td}>DIA</td>
                                    </tr>
                                    <tr>
                                        <td className={classes.td}>RIGHT O.D.</td>
                                        <td className={classes.td}>
                                            <FormControl
                                                className={classes.formControl}
                                                aria-describedby="contactRightSphere-helper-text"
                                            >
                                                <InputLabel
                                                    htmlFor="contactRightSphere-helper"
                                                    className={classes.formLabel}
                                                />
                                                <Input
                                                    id="contactRightSphere-helper"
                                                    className={classes.formInput}
                                                    value={this.state.contactRightSphere}
                                                    type="text"
                                                    onChange={this.handleChange('contactRightSphere')}
                                                />
                                            </FormControl>
                                        </td>
                                        <td className={classes.td}>
                                            <FormControl
                                                className={classes.formControl}
                                                aria-describedby="contactRightCylinder-helper-text"
                                            >
                                                <InputLabel
                                                    htmlFor="contactRightCylinder-helper"
                                                    className={classes.formLabel}
                                                />
                                                <Input
                                                    id="contactRightCylinder-helper"
                                                    className={classes.formInput}
                                                    value={this.state.contactRightCylinder}
                                                    type="text"
                                                    onChange={this.handleChange('contactRightCylinder')}
                                                />
                                            </FormControl>
                                        </td>
                                        <td className={classes.td}>
                                            <FormControl
                                                className={classes.formControl}
                                                aria-describedby="contactRightAxis-helper-text"
                                            >
                                                <InputLabel
                                                    htmlFor="contactRightAxis-helper"
                                                    className={classes.formLabel}
                                                />
                                                <Input
                                                    id="contactRightAxis-helper"
                                                    className={classes.formInput}
                                                    value={this.state.contactRightAxis}
                                                    type="text"
                                                    onChange={this.handleChange('contactRightAxis')}
                                                />
                                            </FormControl>
                                        </td>
                                        <td className={classes.td}>
                                            <FormControl
                                                className={classes.formControl}
                                                aria-describedby="contactRightBase-helper-text"
                                            >
                                                <InputLabel
                                                    htmlFor="contactRightBase-helper"
                                                    className={classes.formLabel}
                                                />
                                                <Input
                                                    id="contactRightBase-helper"
                                                    className={classes.formInput}
                                                    value={this.state.contactRightBase}
                                                    type="text"
                                                    onChange={this.handleChange('contactRightBase')}
                                                />
                                            </FormControl>
                                        </td>
                                        <td className={classes.td}>
                                            <FormControl
                                                className={classes.formControl}
                                                aria-describedby="contactRightAdd-helper-text"
                                            >
                                                <InputLabel
                                                    htmlFor="contactRightAdd-helper"
                                                    className={classes.formLabel}
                                                />
                                                <Input
                                                    id="contactRightAdd-helper"
                                                    className={classes.formInput}
                                                    value={this.state.contactRightAdd}
                                                    type="text"
                                                    onChange={this.handleChange('contactRightAdd')}
                                                />
                                            </FormControl>
                                        </td>
                                        <td className={classes.td}>
                                            <FormControl
                                                className={classes.formControl}
                                                aria-describedby="contactRightDia-helper-text"
                                            >
                                                <InputLabel
                                                    htmlFor="contactRightDia-helper"
                                                    className={classes.formLabel}
                                                />
                                                <Input
                                                    id="contactRightDia-helper"
                                                    className={classes.formInput}
                                                    value={this.state.contactRightDia}
                                                    type="text"
                                                    onChange={this.handleChange('contactRightDia')}
                                                />
                                            </FormControl>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={classes.td}>LEFT O.S.</td>
                                        <td className={classes.td}>
                                            <FormControl
                                                className={classes.formControl}
                                                aria-describedby="contactLeftSphere-helper-text"
                                            >
                                                <InputLabel
                                                    htmlFor="contactLeftSphere-helper"
                                                    className={classes.formLabel}
                                                />
                                                <Input
                                                    id="contactLeftSphere-helper"
                                                    className={classes.formInput}
                                                    value={this.state.contactLeftSphere}
                                                    type="text"
                                                    onChange={this.handleChange('contactLeftSphere')}
                                                />
                                            </FormControl>
                                        </td>
                                        <td className={classes.td}>
                                            <FormControl
                                                className={classes.formControl}
                                                aria-describedby="contactLeftCylinder-helper-text"
                                            >
                                                <InputLabel
                                                    htmlFor="contactLeftCylinder-helper"
                                                    className={classes.formLabel}
                                                />
                                                <Input
                                                    id="contactLeftCylinder-helper"
                                                    className={classes.formInput}
                                                    value={this.state.contactLeftCylinder}
                                                    type="text"
                                                    onChange={this.handleChange('contactLeftCylinder')}
                                                />
                                            </FormControl>
                                        </td>
                                        <td className={classes.td}>
                                            <FormControl
                                                className={classes.formControl}
                                                aria-describedby="contactLeftAxis-helper-text"
                                            >
                                                <InputLabel
                                                    htmlFor="contactLeftAxis-helper"
                                                    className={classes.formLabel}
                                                />
                                                <Input
                                                    id="contactLeftAxis-helper"
                                                    className={classes.formInput}
                                                    value={this.state.contactLeftAxis}
                                                    type="text"
                                                    onChange={this.handleChange('contactLeftAxis')}
                                                />
                                            </FormControl>
                                        </td>
                                        <td className={classes.td}>
                                            <FormControl
                                                className={classes.formControl}
                                                aria-describedby="contactLeftBase-helper-text"
                                            >
                                                <InputLabel
                                                    htmlFor="contactLeftBase-helper"
                                                    className={classes.formLabel}
                                                />
                                                <Input
                                                    id="contactLeftBase-helper"
                                                    className={classes.formInput}
                                                    value={this.state.contactLeftBase}
                                                    type="text"
                                                    onChange={this.handleChange('contactLeftBase')}
                                                />
                                            </FormControl>
                                        </td>
                                        <td className={classes.td}>
                                            <FormControl
                                                className={classes.formControl}
                                                aria-describedby="contactLeftAdd-helper-text"
                                            >
                                                <InputLabel
                                                    htmlFor="contactLeftAdd-helper"
                                                    className={classes.formLabel}
                                                />
                                                <Input
                                                    id="contactLeftAdd-helper"
                                                    className={classes.formInput}
                                                    value={this.state.contactLeftAdd}
                                                    type="text"
                                                    onChange={this.handleChange('contactLeftAdd')}
                                                />
                                            </FormControl>
                                        </td>
                                        <td className={classes.td}>
                                            <FormControl
                                                className={classes.formControl}
                                                aria-describedby="contactLeftDia-helper-text"
                                            >
                                                <InputLabel
                                                    htmlFor="contactLeftDia-helper"
                                                    className={classes.formLabel}
                                                />
                                                <Input
                                                    id="contactLeftDia-helper"
                                                    className={classes.formInput}
                                                    value={this.state.contactLeftDia}
                                                    type="text"
                                                    onChange={this.handleChange('contactLeftDia')}
                                                />
                                            </FormControl>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <FormControl className={classes.formControl} aria-describedby="expiration-date-helper-text">
                            <TextField
                                id="expiration-date-helper"
                                type="date"
                                className={classes.textField}
                                value={this.state.expiration_date}
                                label="Expiration Date"
                                InputLabelProps={{ shrink: true }}
                                onChange={this.handleChange('expiration_date')}
                            />
                        </FormControl>
                        <FormControl className={classes.formControl} aria-describedby="license-helper-text">
                            <InputLabel htmlFor="license-helper" className={classes.formLabel}>
                                License
                            </InputLabel>
                            <Input
                                id="license-helper"
                                className={classes.formInput}
                                value={this.state.license}
                                type="text"
                                onChange={this.handleChange('license')}
                            />
                            {/* {licenseError && ( */}
                            {/*    <FormHelperText id="license-error-text" className={classes.formError}> */}
                            {/*        Please insert license */}
                            {/*    </FormHelperText> */}
                            {/* )} */}
                        </FormControl>
                        <FormControl className={classes.formControl} aria-describedby="doctorName-helper-text">
                            <InputLabel htmlFor="doctorName-helper" className={classes.formLabel}>
                                DOCTOR
                            </InputLabel>
                            <Input
                                id="doctorName-helper"
                                className={classes.formInput}
                                value={this.state.doctorName}
                                type="text"
                                onChange={this.handleChange('doctorName')}
                            />
                            {/* {doctorNameError && ( */}
                            {/*    <FormHelperText id="doctorName-error-text" className={classes.formError}> */}
                            {/*        Please insert Doctor Name */}
                            {/*    </FormHelperText> */}
                            {/* )} */}
                        </FormControl>
                        <FormControl className={classes.formControl} aria-describedby="comments-helper-text">
                            <InputLabel htmlFor="comments-helper" className={classes.formLabel}>
                                Comments
                            </InputLabel>
                            <Input
                                id="comments-helper"
                                className={classes.formInput}
                                value={this.state.comments}
                                defaultValue={this.state.comments}
                                type="text"
                                onChange={this.handleChange('comments')}
                            />
                        </FormControl>
                        <Divider />
                        <h3>Vision</h3>
                        <div className={classes.in}>
                            <FormControl className={classes.formControl} aria-describedby="visionTitle-helper-text">
                                <InputLabel htmlFor="visionTitle-helper" className={classes.formLabel}>
                                    TITLE
                                </InputLabel>
                                <Input
                                    id="visionTitle-helper"
                                    className={classes.formInput}
                                    value={this.state.visionTitle}
                                    type="text"
                                    onChange={this.handleChange('visionTitle')}
                                />
                            </FormControl>
                            <FormControl className={classes.formControl} aria-describedby="visionContent-helper-text">
                                <InputLabel htmlFor="visionContent-helper" className={classes.formLabel}>
                                    CONTENT
                                </InputLabel>
                                <Input
                                    id="visionContent-helper"
                                    className={classes.formInput}
                                    value={this.state.visionContent}
                                    type="text"
                                    onChange={this.handleChange('visionContent')}
                                />
                            </FormControl>

                            <Divider />
                        </div>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            onClick={this.handleClickOpen}
                        >
                            Save
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            onClick={this.gotoBack}
                        >
                            Cancel
                        </Button>
                    </Paper>
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={this.state.openSnackbar}
                        autoHideDuration={3000}
                        onClose={this.closeNotification}
                        onExited={clearNotification}
                        className={classes.snackbar}
                        message={
                            <div className={classes.notification}>
                                {notification.level && notification.level === 'error' && (
                                    <>
                                        <IconError className={classes.iconError} color="error" />
                                        Server error
                                    </>
                                )}
                                {notification.level && notification.level === 'success' && (
                                    <>
                                        <IconError className={classes.iconError} color="primary" />
                                        Data is saved
                                    </>
                                )}
                            </div>
                        }
                        action={[
                            <IconButton key="close" color="inherit" onClick={this.closeNotification}>
                                <IconClose />
                            </IconButton>,
                        ]}
                    />

                    <Dialog
                        open={this.state.modalOpen}
                        onClose={this.handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">Use Google's location service?</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">Are you agreed?</DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleClose} color="primary">
                                No
                            </Button>
                            <Button onClick={this.handleSubmit} color="primary" autoFocus>
                                Yes
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
            );
        }
        return <CircularProgress className={classes.progress} />;
    }
}

UserDetail.propTypes = {
    classes: PropTypes.object.isRequired,
};

const { getAppointmentRequest, getUserDetailInfoRequest, updateUserInfoRequest } = Actions;

function mapStateToProps(state) {
    return {
        user: state.user,
        notification: state.notification,
    };
}

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            push,
            replace,
            getAppointmentRequest,
            getUserDetailInfoRequest,
            updateUserInfoRequest,
        },
        dispatch
    );

export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(UserDetail);
