import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push, replace } from 'react-router-redux';
import Slider from 'react-slick';
import { bindActionCreators, compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
// import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
// import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
// import DialogTitle from '@material-ui/core/DialogTitle';

import moment from 'moment';

import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Slide from '@material-ui/core/Slide';
import { findMonth, getUniqueListBy } from '../util/helpers';
import { Creators as Actions } from '../actions';

// Material UI
const styles = (theme) => ({
    root: {
        flexGrow: 1,
        padding: 20,
        width: '100%',
        minHeight: 'calc(100% - 156px)',
        [theme.breakpoints.down('md')]: {
            marginTop: 20,
        },
    },
    container: {
        position: 'relative',
        margin: '0 auto',
        padding: '30px 0 0 0',
        textAlign: 'center',
        borderBottom: '3px solid #cfe1e3',
    },
    containerDataExists: {
        display: 'flex',
        flexDirection: 'column',
        width: 800,
        position: 'relative',
    },
    content: {
        width: '100%',
        maxWidth: 767,
        margin: '0 auto',
    },
    title: {
        fontSize: 40,
        fontWeight: 700,
        color: '#00070c',
        [theme.breakpoints.down('md')]: {
            fontSize: 30,
        },
        fontFamily: 'Raleway Thin',
    },
    month: {
        textAlign: 'center',
        marginTop: 30,
        marginBottom: 30,
        fontFamily: 'Raleway SemiBold',
    },
    item: {
        textAlign: 'center',
        border: '1px solid #d4d9dd',
        borderLeft: 'none',
        cursor: 'pointer',
    },
    itemActivity: {
        textAlign: 'center',
        border: '1px solid #00a2e1',
        cursor: 'pointer',
    },
    date: {
        fontSize: 30,
        color: '#333333',
        fontFamily: 'Raleway SemiBold',
    },
    dateActivity: {
        fontSize: 30,
        color: '#00a2e1',
        fontFamily: 'Raleway SemiBold',
    },
    day: {
        fontSize: 14,
        color: '#757575',
        fontFamily: 'Raleway Regular',
    },
    times: {},
    time: {
        textAlign: 'center',
        border: '1px solid #d4d9dd',
        fontSize: 20,
        color: '#757575 !important',
        width: 140,
        height: 50,
        margin: 15,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        '&:hover': {
            border: '1px solid #00a2e1',
        },
        fontFamily: 'Raleway Regular',
    },
    timeActivity: {
        textAlign: 'center',
        border: '1px solid #00a2e1',
        fontSize: 20,
        color: '#00a2e1 !important',
        width: 140,
        height: 50,
        margin: 15,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        fontFamily: 'Raleway SemiBold',
    },
    available: {
        fontStyle: 'italic',
        fontSize: 12,
        color: '#757575',
    },
    dialog: {
        paper: {
            background: 'red',
        },
    },
    chooseDateTimeInstruction: {
        marginTop: '15px',
        fontFamily: 'Raleway SemiBold',
        fontSize: '22px',
    },
    h3: {
        color: '#333333',
        fontFamily: 'Raleway SemiBold',
    },
    dayItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    link: {
        lineHeight: 2,
        fontSize: 12,
        color: '#8bb4c2',
        marginTop: 40,
    },
    progress: {
        margin: theme.spacing.unit * 2,
    },
    noDatescontainer: {
        display: 'flex',
        flexDirection: 'column',
        width: 800,
        position: 'relative',
    },
    contentHeader: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        padding: '30px 0',
        textAlign: 'center',
        borderBottom: '3px solid #cfe1e3',
    },
    noDatescontentHeader: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        padding: '30px 0',
        textAlign: 'center',
        borderBottom: '3px solid #cfe1e3',
    },
    noDatestitle: {
        fontSize: 40,
        fontWeight: 700,
        color: '#00070c',
        [theme.breakpoints.down('md')]: {
            fontSize: 30,
        },
        fontFamily: 'Raleway Thin',
    },
    noDateslink: {
        lineHeight: 2,
        fontSize: 12,
        color: '#8bb4c2',
        marginTop: 40,
    },
    noDatescontents: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        minHeight: 'calc(100vh - 370px)',
    },
    noDatesgrid: {
        margin: 0,
        minHeight: 350,
        [theme.breakpoints.down('md')]: {
            margin: '50px 0',
        },
    },
    noDatesin: {
        padding: '50px 30px',
    },
    noDatesh1: {
        width: '100%',
        fontSize: 30,
        fontFamily: 'Raleway ExtraLight',
    },
    noDatesp1: {
        paddingTop: 30,
        width: '100%',
        fontSize: 15,
        lineHeight: 3,
        fontFamily: 'Raleway ExtraLight',
        textAlign: 'center',
        color: '#757575',
    },
    noDatesback: {
        backgroundColor: 'transparent',
        color: '#8bb4c2',
    },
    noDatesconfirmMail: {
        color: '#8bb4c2',
    },
    loadingPickDate: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        maxHeight: 'calc(100vh - 200px)',
    },
});

class PickDate extends Component {
    constructor(props) {
        super(props);

        this.state = {
            weekday: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
            dates: [],
            thisMonth: new Date(),
            times: [],
            originalTimes: [],
            settedTime: null,
            settedDate: null,
        };
    }

    componentWillMount() {
        this.props.getTimeslotsRequest({ field: 'pending' });

        const today = new Date();
        let next = new Date(today.getFullYear(), today.getMonth() + 1, 1);
        if (today.getMonth() === 11) {
            next = new Date(today.getFullYear() + 1, 0, 1);
        }

        this.setState({ thisMonth: moment(today).format('MMM') });
        this.setState({ NextMonth: moment(next).format('MMM') });
    }

    componentWillReceiveProps(nextProps) {
        if (!this.state.dates.length && nextProps.timeslot.isFetched) {
            const datesArr = [];

            nextProps.timeslot.timeslots
                .filter((value) => value.active)
                .forEach((value) => {
                    const dateValue = value.date;
                    const splitDateValue = dateValue.split(' ');
                    const findLongMonthName = findMonth(splitDateValue[0]).long;
                    const day = moment(`${findLongMonthName} ${splitDateValue[1]}, ${new Date().getFullYear()}`).format(
                        'dddd'
                    );
                    const data = {
                        date: value.date,
                        day,
                    };

                    datesArr.push(data);
                });

            const uniqueDatesArr = getUniqueListBy(datesArr, 'date');

            this.setState({ dates: uniqueDatesArr });
        }
    }

    setBookingDate = (date) => {
        const { timeslot } = this.props;

        const times = timeslot.timeslots.filter((t) => t.date === date).map((value) => value.time);

        this.setState({ times: [] });
        this.setState({ times });

        this.setState({ settedDate: date });
    };

    setBookingTime = (time) => {
        if (!this.props.auth.token) {
            localStorage.setItem('guest_is_booked', '1');
        }

        this.setState({ settedTime: time }, () => {
            if (this.state.settedDate) {
                this.props.pickDate({
                    date: this.state.settedDate,
                    time: this.state.settedTime,
                });
            }
        });
    };

    back = () => {
        this.props.push('/coolspot');
    };

    render() {
        const { classes, timeslot } = this.props;
        const { dates, thisMonth, NextMonth, times, settedDate, settedTime } = this.state;

        const settings = {
            dots: false,
            infinite: dates.length > 6,
            speed: 500,
            slidesToShow: 5,
            slidesToScroll: 5,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        dots: false,
                        infinite: dates.length > 6,
                        speed: 500,
                        slidesToShow: 5,
                        slidesToScroll: 5,
                    },
                },
                {
                    breakpoint: 767,
                    settings: {
                        dots: false,
                        infinite: dates.length > 4,
                        speed: 500,
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    },
                },
                {
                    breakpoint: 667,
                    settings: {
                        dots: false,
                        infinite: dates.length > 4,
                        speed: 500,
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    },
                },
                {
                    breakpoint: 567,
                    settings: {
                        dots: false,
                        infinite: dates.length > 3,
                        speed: 500,
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    },
                },
            ],
        };

        if (timeslot.isFetched && !dates.length) {
            return (
                <div className={classes.root}>
                    <div className={classes.noDatescontentHeader}>
                        <div className={classes.noDatescontainer}>
                            <div className={classes.noDatestitle}>
                                <IconButton onClick={this.back} className={classes.noDatesback}>
                                    <ChevronLeftIcon />
                                </IconButton>
                                Oops
                            </div>
                            <div className={classes.noDateslink}>so sorry</div>
                        </div>
                    </div>
                    <div className={classes.noDatescontents}>
                        <div className={classes.noDatescontainer}>
                            <Slide direction="left" in mountOnEnter unmountOnExit>
                                <div className={classes.noDatesgrid}>
                                    <div className={classes.noDatesin}>
                                        <div className={classes.noDatesp1}>
                                            There is no more availability at this location currently. <br />
                                            Please select a different location or try again later for more availability.{' '}
                                            <br />{' '}
                                        </div>
                                    </div>
                                </div>
                            </Slide>
                        </div>
                    </div>
                </div>
            );
        }

        if (timeslot.isFetched && dates.length > 0) {
            return (
                <div className={classes.root}>
                    <div className={classes.contentHeader}>
                        <div className={classes.containerDataExists}>
                            <div className={classes.title}>when would you like your exam?</div>
                            <div className={classes.link}>choose date, then time</div>
                        </div>
                    </div>
                    <div className={classes.content}>
                        <p className={classes.month}>
                            {thisMonth} / {NextMonth}
                        </p>
                        <Slider {...settings}>
                            {dates.map((item, index) => (
                                <div
                                    key={index}
                                    className={settedDate === item.date ? classes.itemActivity : classes.item}
                                    onClick={() => this.setBookingDate(item.date)}
                                >
                                    <p className={classes.day}>{item.day}</p>
                                    <p className={settedDate === item.date ? classes.dateActivity : classes.date}>
                                        {item.date}
                                    </p>
                                </div>
                            ))}
                        </Slider>
                        <Grid className={classes.times} container>
                            <Grid item xs={12} md={4} className={classes.dayItem}>
                                <h3 className={classes.h3}>Morning</h3>
                                {times.length > 0 ? (
                                    times.map((time, index) => {
                                        if (parseInt(time) < 12) {
                                            return (
                                                <Button
                                                    key={index}
                                                    className={
                                                        settedTime === time ? classes.timeActivity : classes.time
                                                    }
                                                    onClick={() => this.setBookingTime(time)}
                                                    disabled={!settedDate}
                                                >
                                                    {time} am
                                                </Button>
                                            );
                                        }
                                        return null;
                                    })
                                ) : (
                                    <p> </p>
                                )}
                            </Grid>

                            <Grid item xs={12} md={4} className={classes.dayItem}>
                                <h3 className={classes.h3}>Afternoon</h3>
                                {times.length > 0 ? (
                                    times.map((time, index) => {
                                        if (
                                            parseInt(time) >= 12 &&
                                            parseInt(time) < 17 &&
                                            parseInt(time) !== 16 &&
                                            parseInt(time) !== 12
                                        ) {
                                            return (
                                                <Button
                                                    key={index}
                                                    className={
                                                        settedTime === time ? classes.timeActivity : classes.time
                                                    }
                                                    onClick={() => this.setBookingTime(time)}
                                                    disabled={!settedDate}
                                                >
                                                    {`${parseInt(time.substr(0, time.length - 1)) - 12} : ${time.substr(
                                                        time.length - 3
                                                    )}`}{' '}
                                                    pm
                                                </Button>
                                            );
                                        }
                                        return null;
                                    })
                                ) : (
                                    <p> </p>
                                )}
                            </Grid>

                            <Grid item xs={12} md={4} className={classes.dayItem}>
                                <h3 className={classes.h3}>Evening</h3>
                                {times.length > 0 ? (
                                    times.map((time, index) => {
                                        if (parseInt(time) >= 17 && parseInt(time) < 20) {
                                            return (
                                                <Button
                                                    key={index}
                                                    className={
                                                        settedTime === time ? classes.timeActivity : classes.time
                                                    }
                                                    onClick={() => this.setBookingTime(time)}
                                                    disabled={!settedDate}
                                                >
                                                    {`${parseInt(time.substr(0, time.length - 1)) - 12} : ${time.substr(
                                                        time.length - 3
                                                    )}`}{' '}
                                                    pm
                                                </Button>
                                            );
                                        }
                                        return null;
                                    })
                                ) : (
                                    <p> </p>
                                )}
                            </Grid>
                        </Grid>
                    </div>
                </div>
            );
        }

        return (
            <div className={classes.loadingPickDate}>
                <CircularProgress size={60} color="blue" />
            </div>
        );
    }
}

PickDate.propTypes = {
    classes: PropTypes.object.isRequired,
};

const { pickDate, getTimeslotsRequest } = Actions;

function mapStateToProps(state) {
    return {
        user: state.user,
        auth: state.user,
        timeslot: state.timeslot,
    };
}

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            push,
            replace,
            pickDate,
            getTimeslotsRequest,
        },
        dispatch
    );

export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(PickDate);
