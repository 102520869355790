import Immutable from 'seamless-immutable';
import { createReducer } from 'reduxsauce';
import { Types } from '../actions';

const INITIAL_STATE = Immutable({
    error: null,
    isFetching: false,
    isFetched: false,
    isCreating: false,
    isCreated: false,
    timeslots: [],
});

const getTimeslotsRequest = (state) => {
    const newState = Immutable(state);
    return newState.merge({
        isFetching: true,
        isFetched: false,
    });
};

const getTimeslotsSuccess = (state, { timeslots }) =>
    state.merge({
        timeslots,
        isFetching: false,
        isFetched: true,
    });

const getTimeslotsFailed = (state, { error }) =>
    state.merge({
        isFetching: false,
        isFetched: true,
        error,
    });

const createTimeslotRequest = (state) => {
    const newState = Immutable(state);
    return newState.merge({
        isCreating: true,
    });
};

const createTimeslotSuccess = (state, { data }) =>
    state.merge({
        timeslots: [...state.timeslots, data],
        isCreating: false,
        isCreated: true,
    });

const createTimeslotFailed = (state, { error }) =>
    state.merge({
        isCreating: false,
        isCreated: true,
        error,
    });

const removeTimeslotRequest = (state) => {
    const newState = Immutable(state);
    return newState.merge({
        isCreating: true,
    });
};

const removeTimeslotSuccess = (state, { timeslot }) =>
    state.merge({
        timeslots: state.timeslots.filter((p) => p._id !== timeslot._id),
        isCreating: false,
        isCreated: true,
    });

const removeTimeslotFailed = (state, { error }) =>
    state.merge({
        isCreating: false,
        isCreated: true,
        error,
    });

const disableTimeslotRequest = (state) => {
    const newState = Immutable(state);
    return newState.merge({
        isCreating: true,
    });
};

const disableTimeslotSuccess = (state, { timeslot }) => {
    const newTimeslots = state.timeslots.map((item) => {
        if (item._id === timeslot._id) {
            return timeslot;
        }
        return item;
    });
    return state.merge({
        timeslots: newTimeslots,
        isCreating: false,
        isCreated: true,
    });
};

const disableTimeslotFailed = (state, { error }) =>
    state.merge({
        isCreating: false,
        isCreated: true,
        error,
    });

const enableTimeslotRequest = (state) => {
    const newState = Immutable(state);
    return newState.merge({
        isCreating: true,
    });
};

const enableTimeslotSuccess = (state, { timeslot }) => {
    const newTimeslots = state.timeslots.map((item) => {
        if (item._id === timeslot._id) {
            return timeslot;
        }
        return item;
    });
    return state.merge({
        timeslots: newTimeslots,
        isCreating: false,
        isCreated: true,
    });
};

const enableTimeslotFailed = (state, { error }) =>
    state.merge({
        isCreating: false,
        isCreated: true,
        error,
    });

// map our types to our handlers
const ACTION_HANDLERS = {
    [Types.GET_TIMESLOTS_REQUEST]: getTimeslotsRequest,
    [Types.GET_TIMESLOTS_SUCCESS]: getTimeslotsSuccess,
    [Types.GET_TIMESLOTS_FAILED]: getTimeslotsFailed,
    [Types.CREATE_TIMESLOT_REQUEST]: createTimeslotRequest,
    [Types.CREATE_TIMESLOT_SUCCESS]: createTimeslotSuccess,
    [Types.CREATE_TIMESLOT_FAILED]: createTimeslotFailed,
    [Types.REMOVE_TIMESLOT_REQUEST]: removeTimeslotRequest,
    [Types.REMOVE_TIMESLOT_SUCCESS]: removeTimeslotSuccess,
    [Types.REMOVE_TIMESLOT_FAILED]: removeTimeslotFailed,
    [Types.DISABLE_TIMESLOT_REQUEST]: disableTimeslotRequest,
    [Types.DISABLE_TIMESLOT_SUCCESS]: disableTimeslotSuccess,
    [Types.DISABLE_TIMESLOT_FAILED]: disableTimeslotFailed,
    [Types.ENABLE_TIMESLOT_REQUEST]: enableTimeslotRequest,
    [Types.ENABLE_TIMESLOT_SUCCESS]: enableTimeslotSuccess,
    [Types.ENABLE_TIMESLOT_FAILED]: enableTimeslotFailed,
};

export default createReducer(INITIAL_STATE, ACTION_HANDLERS);
