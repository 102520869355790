import { path } from 'ramda';
// import { push } from 'react-router-redux';
import { call, put, take, takeLatest, takeEvery } from 'redux-saga/effects';

import { Creators as Actions, Types } from '../actions';
// import { history } from '../config/store'
// import { delay } from 'redux-saga'

export default (api) => {
    function* getPlaces(data) {
        let error = 'Something went wrong.';
        let placeRes;

        if (data) {
            if (data.data) {
                placeRes = yield call(api.getPlaces, data.data);
            } else {
                placeRes = yield call(api.getPlaces);
            }
        } else {
            yield take(Types.GET_PLACES_REQUEST);
            placeRes = yield call(api.getPlaces);
        }

        if (placeRes.ok) {
            const success = 'Great Things Happening';
            return yield put(Actions.getPlacesSuccess(placeRes.data.data, success));
        }
        error = path(['data', 'error_msg'], placeRes) || error;
        yield put(Actions.getPlacesFailed(error));
    }

    function* createPlace(data) {
        let error = 'Something went wrong.';
        const createRes = yield call(api.createPlace, {
            ...data.value,
        });

        if (createRes.ok) {
            const success = 'Great Things Happening.';
            return yield put(Actions.createPlaceSuccess(createRes.data.data, success));
        }
        error = path(['data', 'error_msg'], createRes) || error;
        yield put(Actions.createPlaceFailed(error));
    }

    function* watchPlaces() {
        yield takeEvery(Types.GET_PLACES_REQUEST, getPlaces);
    }

    function* watchCreatePlace() {
        yield takeLatest(Types.CREATE_PLACE_REQUEST, createPlace);
    }

    function* removePlace({ data }) {
        let error = 'Something went wrong.';
        const removeRes = yield call(api.removePlace, data.id);

        if (removeRes.ok) {
            const success = 'Great Things Happening.';
            return yield put(Actions.removePlaceSuccess(removeRes.data.data, success));
        }
        error = path(['data', 'error_msg'], removeRes) || error;
        yield put(Actions.removePlaceFailed(error));
    }

    function* watchRemovePlace() {
        yield takeLatest(Types.REMOVE_PLACE_REQUEST, removePlace);
    }

    function* enablePlace({ data }) {
        console.log(data);
        let error = 'Something went wrong.';
        const enableRes = yield call(api.enablePlace, data.id);

        if (enableRes.ok) {
            const success = 'Great Things Happening.';
            return yield put(Actions.enablePlaceSuccess(enableRes.data.data, success));
        }
        error = path(['data', 'error_msg'], enableRes) || error;
        yield put(Actions.enablePlaceFailed(error));
    }

    function* watchEnablePlace() {
        yield takeLatest(Types.ENABLE_PLACE_REQUEST, enablePlace);
    }

    function* disablePlace({ data }) {
        let error = 'Something went wrong.';
        const disableRes = yield call(api.disablePlace, data.id);

        if (disableRes.ok) {
            const success = 'Great Things Happening.';
            return yield put(Actions.disablePlaceSuccess(disableRes.data.data, success));
        }
        error = path(['data', 'error_msg'], disableRes) || error;
        yield put(Actions.disablePlaceFailed(error));
    }

    function* watchDisablePlace() {
        yield takeLatest(Types.DISABLE_PLACE_REQUEST, disablePlace);
    }

    return {
        getPlaces,
        watchPlaces,
        watchCreatePlace,
        watchRemovePlace,
        watchEnablePlace,
        watchDisablePlace,
    };
};
