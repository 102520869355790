import React, { Component } from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';

export default class AdminTableHead extends Component {
    createSortHandler = (property, sortBy, row) => (event) => {
        if (typeof row.sorting !== 'undefined' && !row.sorting) return;

        this.props.onRequestSort(event, property, sortBy, row);
    };

    render() {
        const { order, orderBy, rows } = this.props;

        return (
            <TableHead>
                <TableRow>
                    {rows.map(
                        (row) => (
                            <TableCell key={row.id} sortDirection={orderBy === row.id ? order : false}>
                                <Tooltip
                                    title="Sort"
                                    placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={orderBy === row.id}
                                        direction={order}
                                        onClick={this.createSortHandler(row.id, order, row)}
                                    >
                                        {row.label}
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                        ),
                        this
                    )}
                </TableRow>
            </TableHead>
        );
    }
}
