import notification from './notification';
import auth from './auth';
import user from './user';
import place from './place';
import timeslot from './timeslot';

export default {
    notification,
    auth,
    user,
    place,
    timeslot,
};
