import { take, select } from 'redux-saga/effects';
import { REHYDRATE } from 'redux-persist';
import { Types } from '../actions';

const getToken = (state) => state.auth.token;

export default (api) => {
    // Booting
    function* bootingFlow() {
        while (true) {
            yield take([REHYDRATE, Types.LOGIN_AUTH_SUCCESS, Types.LOGIN_USER_SUCCESS]);
            const token = yield select(getToken);

            // Start booting
            if (token) {
                api.setAuthHeader(token);
            }
        }
    }

    return {
        bootingFlow,
    };
};
