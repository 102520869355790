import Immutable from 'seamless-immutable';
import { createReducer } from 'reduxsauce';
import { Types } from '../actions';

const INITIAL_STATE = Immutable({
    error: null,
    isFetching: false,
    isFetched: false,
    isCreating: false,
    isCreated: false,
    places: [],
});

const getPlacesRequest = (state) => {
    const newState = Immutable(state);
    return newState.merge({
        isFetching: true,
    });
};

const getPlacesSuccess = (state, { places }) =>
    state.merge({
        places,
        isFetching: false,
        isFetched: true,
    });

const getPlacesFailed = (state, { error }) =>
    state.merge({
        isFetching: false,
        isFetched: true,
        error,
    });

const createPlaceRequest = (state) => {
    const newState = Immutable(state);
    return newState.merge({
        isCreating: true,
    });
};

const createPlaceSuccess = (state, { data }) =>
    state.merge({
        places: [...state.places, data],
        isCreating: false,
        isCreated: true,
    });

const createPlaceFailed = (state, { error }) =>
    state.merge({
        isCreating: false,
        isCreated: true,
        error,
    });

const removePlaceRequest = (state) => {
    const newState = Immutable(state);
    return newState.merge({
        isCreating: true,
    });
};

const removePlaceSuccess = (state, { place }) =>
    state.merge({
        places: state.places.filter((p) => p._id !== place._id),
        isCreating: false,
        isCreated: true,
    });

const removePlaceFailed = (state, { error }) =>
    state.merge({
        isCreating: false,
        isCreated: true,
        error,
    });

const disablePlaceRequest = (state) => {
    const newState = Immutable(state);
    return newState.merge({
        isCreating: true,
    });
};

const disablePlaceSuccess = (state, { place }) => {
    const newPlaces = state.places.map((item) => {
        if (item._id === place._id) {
            return place;
        }
        return item;
    });
    return state.merge({
        places: newPlaces,
        isCreating: false,
        isCreated: true,
    });
};

const disablePlaceFailed = (state, { error }) =>
    state.merge({
        isCreating: false,
        isCreated: true,
        error,
    });

const enablePlaceRequest = (state) => {
    const newState = Immutable(state);
    return newState.merge({
        isCreating: true,
    });
};

const enablePlaceSuccess = (state, { place }) => {
    const newPlaces = state.places.map((item) => {
        if (item._id === place._id) {
            return place;
        }
        return item;
    });
    return state.merge({
        places: newPlaces,
        isCreating: false,
        isCreated: true,
    });
};

const enablePlaceFailed = (state, { error }) =>
    state.merge({
        isCreating: false,
        isCreated: true,
        error,
    });

// map our types to our handlers
const ACTION_HANDLERS = {
    [Types.GET_PLACES_REQUEST]: getPlacesRequest,
    [Types.GET_PLACES_SUCCESS]: getPlacesSuccess,
    [Types.GET_PLACES_FAILED]: getPlacesFailed,
    [Types.CREATE_PLACE_REQUEST]: createPlaceRequest,
    [Types.CREATE_PLACE_SUCCESS]: createPlaceSuccess,
    [Types.CREATE_PLACE_FAILED]: createPlaceFailed,
    [Types.REMOVE_PLACE_REQUEST]: removePlaceRequest,
    [Types.REMOVE_PLACE_SUCCESS]: removePlaceSuccess,
    [Types.REMOVE_PLACE_FAILED]: removePlaceFailed,
    [Types.DISABLE_PLACE_REQUEST]: disablePlaceRequest,
    [Types.DISABLE_PLACE_SUCCESS]: disablePlaceSuccess,
    [Types.DISABLE_PLACE_FAILED]: disablePlaceFailed,
    [Types.ENABLE_PLACE_REQUEST]: enablePlaceRequest,
    [Types.ENABLE_PLACE_SUCCESS]: enablePlaceSuccess,
    [Types.ENABLE_PLACE_FAILED]: enablePlaceFailed,
};

export default createReducer(INITIAL_STATE, ACTION_HANDLERS);
