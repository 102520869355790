import { pipe, converge, find, nthArg, propEq, all, contains, toLower, split } from 'ramda';
import moment from 'moment';

export const formatDate = (value, format) => (value ? moment(value).format(format || 'DD/MM/YYYY HH:mm') : '');

export const findById = converge(find, [pipe(nthArg(0), propEq('id')), nthArg(1)]);

export const searchInString = (string, { value }) =>
    all((keyword) => contains(toLower(keyword), toLower(string)))(split(' ', value));

export const searchInArray = (string, { value }) => contains(string, value);

export const sortDecimal = (a, b) => a - b;

const monthsName = [
    {
        short: 'Jan',
        long: 'January',
    },
    {
        short: 'Feb',
        long: 'February',
    },
    {
        short: 'Mar',
        long: 'March',
    },
    {
        short: 'Apr',
        long: 'April',
    },
    {
        short: 'May',
        long: 'May',
    },
    {
        short: 'Jun',
        long: 'June',
    },
    {
        short: 'Jul',
        long: 'July',
    },
    {
        short: 'Aug',
        long: 'August',
    },
    {
        short: 'Sep',
        long: 'September',
    },
    {
        short: 'Oct',
        long: 'October',
    },
    {
        short: 'Nov',
        long: 'November',
    },
    {
        short: 'Dec',
        long: 'December',
    },
];

export const findMonth = (shortMonthName) => monthsName.find((item) => item.short === shortMonthName);

export const getUniqueListBy = (arr, key) => [...new Map(arr.map((item) => [item[key], item])).values()];

export const desc = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
};

export const getSorting = (order, orderBy) =>
    order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
