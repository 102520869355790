import React, { Component } from 'react';
import { Link /* , withRouter */ } from 'react-router-dom';
import { push, replace } from 'react-router-redux';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';

import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
// import Typography from '@material-ui/core/Typography';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MenuIcon from '@material-ui/icons/Menu';
import PersonIcon from '@material-ui/icons/Person';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import StarIcon from '@material-ui/icons/Star';
import MyLocation from '@material-ui/icons/MyLocation';
import Timeline from '@material-ui/icons/Timeline';
// import Button from '@material-ui/core/Button'
import classNames from 'classnames';

import { Creators as Actions } from '../../actions';

const drawerWidth = 240;

const styles = (theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing.unit * 7 + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing.unit * 9 + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        // flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
    link: {
        display: 'flex',
        textDecoration: 'none',
    },
    logout: {
        position: 'absolute',
        right: 0,
        color: '#ffffff',
    },
    header: {
        textDecoration: 'none',
        color: '#ffffff',
    },
});

class Layout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        };
    }

    handleDrawerOpen = () => {
        this.setState({ open: true });
    };

    handleDrawerClose = () => {
        this.setState({ open: false });
    };

    logout = () => {
        this.props.logout();
        this.props.push('/account');
        // this.props.navigate('/account');
    };

    render() {
        const { classes } = this.props;

        return (
            <>
                <CssBaseline />
                <AppBar
                    position="fixed"
                    className={classNames(classes.appBar, {
                        [classes.appBarShift]: this.state.open,
                    })}
                >
                    <Toolbar disableGutters={!this.state.open}>
                        <IconButton
                            color="inherit"
                            aria-label="Open drawer"
                            onClick={this.handleDrawerOpen}
                            className={classNames(classes.menuButton, {
                                [classes.hide]: this.state.open,
                            })}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Link to="/admin" className={classes.header}>
                            WGV
                        </Link>
                        <Link to="/#" className={classes.logout} onClick={this.logout}>
                            <ListItemIcon>
                                <LogoutIcon />
                            </ListItemIcon>
                        </Link>
                    </Toolbar>
                </AppBar>
                <Drawer
                    variant="permanent"
                    className={classNames(classes.drawer, {
                        [classes.drawerOpen]: this.state.open,
                        [classes.drawerClose]: !this.state.open,
                    })}
                    classes={{
                        paper: classNames({
                            [classes.drawerOpen]: this.state.open,
                            [classes.drawerClose]: !this.state.open,
                        }),
                    }}
                    open={this.state.open}
                >
                    <div className={classes.toolbar}>
                        <IconButton onClick={this.handleDrawerClose}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </div>
                    <Divider />
                    <List>
                        <Link to="/admin" className={classes.link}>
                            <ListItem button>
                                <ListItemIcon>
                                    <ShoppingCartIcon />
                                </ListItemIcon>
                                <ListItemText primary="Booking" />
                            </ListItem>
                        </Link>
                        <Link to="/admin/user" className={classes.link}>
                            <ListItem button>
                                <ListItemIcon>
                                    <PersonIcon />
                                </ListItemIcon>
                                <ListItemText primary="Users" />
                            </ListItem>
                        </Link>
                        <Link to="/admin/user/reschedule" className={classes.link}>
                            <ListItem button>
                                <ListItemIcon>
                                    <StarIcon />
                                </ListItemIcon>
                                <ListItemText primary="Reschedule" />
                            </ListItem>
                        </Link>
                        <Link to="/admin/timeslots" className={classes.link}>
                            <ListItem button>
                                <ListItemIcon>
                                    <Timeline />
                                </ListItemIcon>
                                <ListItemText primary="Time Slots" />
                            </ListItem>
                        </Link>
                        <Link to="/admin/places" className={classes.link}>
                            <ListItem button>
                                <ListItemIcon>
                                    <MyLocation />
                                </ListItemIcon>
                                <ListItemText primary="Places" />
                            </ListItem>
                        </Link>
                    </List>
                </Drawer>
            </>
        );
    }
}

const { logout } = Actions;

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            push,
            replace,
            logout,
        },
        dispatch
    );

export default compose(withStyles(styles), connect(null, mapDispatchToProps))(Layout);
