import { all } from 'redux-saga/effects';
import api from '../services/api';

// Sagas
import startup from './startup';
import runtime from './runtime';
import auth from './auth';
import user from './user';
import place from './place';
import timeslot from './timeslot';

// Start the daemons
export default function* root() {
    yield all([
        startup(api).bootingFlow(),
        runtime(api).watchersFlow(),
        auth(api).loginFlow(),
        auth(api).logoutFlow(),
        auth(api).registerFlow(),
        auth(api).watchForgotFlow(),
        auth(api).watchResetFlow(),
        auth(api).watchUserUpdateFlow(),
        auth(api).watchConfirmRegisterFlow(),
        auth(api).watchUserInfoFlow(),
        user(api).watchSetBook(),
        user(api).getAppointments(),
        user(api).watchAppointments(),
        user(api).watchUserAppointment(),
        user(api).watchUsers(),
        user(api).watchUpdateUserInfo(),
        user(api).watchBookingDates(),
        user(api).watchConfirmAppointment(),
        user(api).watchCancelAppointmentRequest(),
        user(api).watchSendEmailRequest(),
        user(api).watchUserDetailInfoRequest(),
        // place(api).getPlaces(),
        place(api).watchPlaces(),
        place(api).watchCreatePlace(),
        place(api).watchRemovePlace(),
        place(api).watchEnablePlace(),
        place(api).watchDisablePlace(),
        // timeslot(api).getTimeslots(),
        timeslot(api).watchTimeslots(),
        timeslot(api).watchCreateTimeslot(),
        timeslot(api).watchRemoveTimeslot(),
        timeslot(api).watchEnableTimeslot(),
        timeslot(api).watchDisableTimeslot(),
    ]);
}
