import Immutable from 'seamless-immutable';
import { createReducer } from 'reduxsauce';
import { Types } from '../actions';

const INITIAL_STATE = Immutable({
    uiLoadingIn: false,
    uiLoadingNew: false,
    token: null,
    user: null,
    error: null,
});

// AUTH
const loginAttempt = (state) => {
    const newState = Immutable(state);
    return newState.merge({
        uiLoadingIn: true,
    });
};

const loginAuthSuccess = (state, { auth }) =>
    state.merge({
        user: auth.user,
        token: auth.token,
        userAppointment: auth.appointment,
        uiLoadingIn: false,
    });

const loginUserSuccess = (state, { user }) =>
    state.merge({
        uiLoadingIn: false,
        user,
    });

const loginFailure = (state, { error }) =>
    state.merge({
        uiLoadingIn: false,
        error,
    });

const registerAttempt = (state) => {
    const newState = Immutable(state);
    return newState.merge({
        uiLoadingNew: true,
        error: INITIAL_STATE.error,
    });
};

const registerSuccess = (state, { user }) =>
    state.merge({
        token: user.token,
    });

const registerFailure = (state, { error }) =>
    state.merge({
        uiLoadingNew: false,
        error,
    });

const forgotAttempt = (state) => {
    const newState = Immutable(state);
    return newState.merge({
        uiLoadingIn: true,
    });
};

const forgotSuccess = (state) =>
    state.merge({
        uiLoadingIn: false,
    });

const forgotFailed = (state, { error }) =>
    state.merge({
        uiLoadingIn: false,
        error,
    });

const resetAttempt = (state) => {
    const newState = Immutable(state);
    return newState.merge({
        uiLoadingIn: true,
    });
};

const resetSuccess = (state, { user }) =>
    state.merge({
        uiLoadingIn: false,
        user,
    });

const resetFailed = (state, { error }) =>
    state.merge({
        uiLoadingIn: false,
        error,
    });

const updateUserProfileRequest = (state) => {
    const newState = Immutable(state);
    return newState.merge({
        uiLoadingIn: true,
    });
};

const updateUserProfileSuccess = (state, { user }) =>
    state.merge({
        uiLoadingIn: false,
        user,
    });

const updateUserProfileFailed = (state, { error }) =>
    state.merge({
        uiLoadingIn: false,
        error,
    });

const confirmRegisterAttempt = (state) => {
    const newState = Immutable(state);
    return newState.merge({
        uiLoadingIn: true,
    });
};

const confirmRegisterSuccess = (state, { data }) =>
    state.merge({
        uiLoadingIn: false,
        user: data.user,
        token: data.token,
        userAppointment: data.userAppointment,
    });

const confirmRegisterFailed = (state, { error }) =>
    state.merge({
        uiLoadingIn: false,
        error,
    });

const removeUserAppointment = (state) => {
    const newState = Immutable(state);
    return newState.merge({
        userAppointment: null,
    });
};

const setUserAppointment = (state, { booking }) => {
    const newState = Immutable(state);
    return newState.merge({
        userAppointment: booking,
    });
};

const getUserInfoRequest = (state) => {
    const newState = Immutable(state);
    return newState.merge({
        isFetching: true,
    });
};

const getUserInfoSuccess = (state, { user }) =>
    state.merge({
        user,
        isFetching: false,
        isFetched: true,
    });

const getUserInfoFailed = (state, { error }) =>
    state.merge({
        isFetching: false,
        isFetched: true,
        error,
    });

const logout = (state) => INITIAL_STATE;

// map our types to our handlers
const ACTION_HANDLERS = {
    // AUTH
    [Types.LOGIN_ATTEMPT]: loginAttempt,
    [Types.LOGIN_AUTH_SUCCESS]: loginAuthSuccess,
    [Types.LOGIN_USER_SUCCESS]: loginUserSuccess,
    [Types.LOGIN_FAILURE]: loginFailure,
    [Types.REGISTER_ATTEMPT]: registerAttempt,
    [Types.REGISTER_SUCCESS]: registerSuccess,
    [Types.REGISTER_FAILURE]: registerFailure,
    [Types.FORGOT_ATTEMPT]: forgotAttempt,
    [Types.FORGOT_SUCCESS]: forgotSuccess,
    [Types.FORGOT_FAILED]: forgotFailed,
    [Types.RESET_ATTEMPT]: resetAttempt,
    [Types.RESET_SUCCESS]: resetSuccess,
    [Types.RESET_FAILED]: resetFailed,
    [Types.UPDATE_USER_PROFILE_REQUEST]: updateUserProfileRequest,
    [Types.UPDATE_USER_PROFILE_SUCCESS]: updateUserProfileSuccess,
    [Types.UPDATE_USER_PROFILE_FAILED]: updateUserProfileFailed,
    [Types.CONFIRM_REGISTER_ATTEMPT]: confirmRegisterAttempt,
    [Types.CONFIRM_REGISTER_SUCCESS]: confirmRegisterSuccess,
    [Types.CONFIRM_REGISTER_FAILED]: confirmRegisterFailed,
    [Types.REMOVE_USER_APPOINTMENT]: removeUserAppointment,
    [Types.SET_USER_APPOINTMENT]: setUserAppointment,
    [Types.GET_USER_INFO_REQUEST]: getUserInfoRequest,
    [Types.GET_USER_INFO_SUCCESS]: getUserInfoSuccess,
    [Types.GET_USER_INFO_FAILED]: getUserInfoFailed,
    // Reset
    [Types.LOGOUT]: logout,
};

export default createReducer(INITIAL_STATE, ACTION_HANDLERS);
