import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push, replace } from 'react-router-redux';
import { bindActionCreators, compose } from 'redux';
// import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
// import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
// import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';

import { Creators as Actions } from '../../actions';
import AdminTableHead from '../../components/admin/TableHeader';

const styles = (theme) => ({
    formControl: {
        margin: '10px 0',
        width: '100%',
    },
    formLabel: {
        color: '#717171 !important',
    },
    formInput: {
        color: '#717171',
        '&:before': {
            borderColor: '#717171',
        },
        '&:after': {
            borderColor: '#717171',
        },
    },
    formError: {
        color: 'red',
    },
    table: {
        minWidth: 520,
    },
    tableWrapper: {
        marginTop: 45,
        overflowX: 'auto',
    },
    progress: {
        margin: theme.spacing.unit * 2,
    },
    button: {
        margin: theme.spacing.unit,
    },
    addressOne: {
        fontWeight: 'bold',
        lineHeight: 1.5,
        fontFamily: 'Raleway Regular',
    },
    addressTwo: {
        fontFamily: 'Raleway Regular',
    },
});

class AdminPlaces extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            addressOne: '',
            addressTwo: '',

            order: 'asc',
            orderBy: 'addressOne',

            selected: [],
            page: 0,
            rowsPerPage: 10,
            open: false,
            rows: [
                { id: 'addressOne', name: '_id', label: 'Address One' },
                { id: 'addressTwo', label: 'Address Two', sorting: false },
                { id: 'active', name: 'active', label: 'Status' },
                { id: 'action', label: 'Action', sorting: false },
            ],
        };
    }

    componentWillMount() {
        this.props.getPlacesRequest();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.place.isFetched) {
            this.setState({ data: nextProps.place.places });
        }
    }

    setAddress = (address) => {
        this.setState({ address1: address.formattedSuggestion.mainText });
        this.setState({ address2: address.formattedSuggestion.secondaryText });
    };

    handleValueChange = (name) => (event) => {
        this.setState({
            [name]: event.target.value,
        });
    };

    handleChange = (row) => (event) => {
        switch (event.target.value) {
            case 'remove':
                this.props.removePlaceRequest({
                    id: row._id,
                });
                break;
            case 'enable':
                this.props.enablePlaceRequest({
                    id: row._id,
                });
                break;
            case 'disable':
                this.props.disablePlaceRequest({
                    id: row._id,
                });
                break;
            default:
                break;
        }
    };

    handleRequestSort = (event, property, sortBy, row) => {
        this.props.getPlacesRequest({
            field: row.name,
            sort_by: sortBy,
        });

        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        this.setState({ order, orderBy });
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value });
    };

    handleClickSave = () => {
        const { addressOne = '', addressTwo = '' } = this.state;
        if (addressOne && addressTwo) {
            this.props.createPlaceRequest({
                addressOne,
                addressTwo,
            });
            this.setState({
                addressOne: '',
                addressTwo: '',
            });
        }
    };

    goToDetailPage = (user) => {
        this.props.push(`/admin/user/${user._id}`);
    };

    render() {
        const { classes, place } = this.props;
        const { data, order, orderBy, selected, rowsPerPage, page, rows } = this.state;

        if (place.isFetched) {
            return (
                <>
                    <div>
                        <p>New Place</p>
                        <FormControl className={classes.formControl} aria-describedby="addressOne-text">
                            <InputLabel htmlFor="addressOne-helper" className={classes.formLabel}>
                                Address One
                            </InputLabel>
                            <Input
                                id="addressOne-helper"
                                className={classes.formInput}
                                value={this.state.addressOne}
                                type="text"
                                onChange={this.handleValueChange('addressOne')}
                            />
                        </FormControl>
                        <FormControl className={classes.formControl} aria-describedby="addressTwo-text">
                            <InputLabel htmlFor="addressTwo-helper" className={classes.formLabel}>
                                Address Two
                            </InputLabel>
                            <Input
                                id="addressTwo-helper"
                                className={classes.formInput}
                                value={this.state.addressTwo}
                                type="text"
                                onChange={this.handleValueChange('addressTwo')}
                            />
                        </FormControl>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            onClick={() => this.handleClickSave()}
                        >
                            Save
                        </Button>
                    </div>

                    <Paper>
                        <div className={classes.tableWrapper}>
                            <Table className={classes.table} aria-labelledby="tableTitle">
                                <AdminTableHead
                                    numSelected={selected.length}
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={this.handleRequestSort}
                                    rowCount={data.length}
                                    rows={rows}
                                />
                                <TableBody>
                                    {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((n) => (
                                        <TableRow hover tabIndex={-1} key={n._id}>
                                            <TableCell>{n.addressOne}</TableCell>
                                            <TableCell>{n.addressTwo}</TableCell>
                                            <TableCell>{n.active ? 'Active' : 'Inactive'}</TableCell>
                                            <TableCell>
                                                <Select
                                                    native
                                                    onChange={this.handleChange(n)}
                                                    inputProps={{
                                                        name: 'actionType',
                                                        id: 'actionType-native-simple',
                                                    }}
                                                >
                                                    <option value="">Select Action</option>
                                                    <option value="enable">Enable Place</option>
                                                    <option value="disable">Disable Place</option>
                                                    <option value="remove">Remove Place</option>
                                                </Select>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                        <TablePagination
                            component="div"
                            count={data.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            backIconButtonProps={{
                                'aria-label': 'Previous Page',
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'Next Page',
                            }}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                    </Paper>
                </>
            );
        }
        return <CircularProgress className={classes.progress} />;
    }
}

AdminPlaces.propTypes = {
    classes: PropTypes.object.isRequired,
};

const { getPlacesRequest, createPlaceRequest, removePlaceRequest, enablePlaceRequest, disablePlaceRequest } = Actions;

const mapStateToProps = (state) => ({
    place: state.place,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            push,
            replace,
            getPlacesRequest,
            createPlaceRequest,
            removePlaceRequest,
            enablePlaceRequest,
            disablePlaceRequest,
        },
        dispatch
    );

export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(AdminPlaces);
