import { path } from 'ramda';
// import { push } from 'react-router-redux';
import { call, put, take, takeLatest, takeEvery } from 'redux-saga/effects';

import { Creators as Actions, Types } from '../actions';
// import { history } from '../config/store'
// import { delay } from 'redux-saga'

export default (api) => {
    function* getTimeslots(data) {
        let error = 'Something went wrong.';
        let timeslotsRes;

        if (data) {
            if (data.data) {
                timeslotsRes = yield call(api.getTimeslots, data.data);
            } else {
                timeslotsRes = yield call(api.getTimeslots);
            }
        } else {
            yield take(Types.GET_TIMESLOTS_REQUEST);
            timeslotsRes = yield call(api.getTimeslots);
        }

        if (timeslotsRes.ok) {
            const success = 'Great Things Happening';
            return yield put(Actions.getTimeslotsSuccess(timeslotsRes.data.data, success));
        }

        error = path(['data', 'error_msg'], timeslotsRes) || error;
        yield put(Actions.getTimeslotsFailed(error));
    }

    function* createTimeslot(data) {
        let error = 'Something went wrong.';
        const createRes = yield call(api.createTimeslot, {
            ...data.value,
        });

        if (createRes.ok) {
            const success = 'Great Things Happening.';
            return yield put(Actions.createTimeslotSuccess(createRes.data.data, success));
        }

        error = path(['data', 'error_msg'], createRes) || error;
        yield put(Actions.createTimeslotFailed(error));
    }

    function* watchTimeslots() {
        yield takeEvery(Types.GET_TIMESLOTS_REQUEST, getTimeslots);
    }

    function* watchCreateTimeslot() {
        yield takeLatest(Types.CREATE_TIMESLOT_REQUEST, createTimeslot);
    }

    function* removeTimeslot({ data }) {
        let error = 'Something went wrong.';
        const removeRes = yield call(api.removeTimeslot, data.id);

        if (removeRes.ok) {
            const success = 'Great Things Happening.';
            return yield put(Actions.removeTimeslotSuccess(removeRes.data.data, success));
        }
        error = path(['data', 'error_msg'], removeRes) || error;
        yield put(Actions.removeTimeslotFailed(error));
    }

    function* watchRemoveTimeslot() {
        yield takeLatest(Types.REMOVE_TIMESLOT_REQUEST, removeTimeslot);
    }

    function* disableTimeslot({ data }) {
        let error = 'Something went wrong.';
        const disableRes = yield call(api.disableTimeslot, data.id);

        if (disableRes.ok) {
            const success = 'Great Things Happening.';
            return yield put(Actions.disableTimeslotSuccess(disableRes.data.data, success));
        }
        error = path(['data', 'error_msg'], disableRes) || error;
        yield put(Actions.disableTimeslotFailed(error));
    }

    function* watchDisableTimeslot() {
        yield takeLatest(Types.DISABLE_TIMESLOT_REQUEST, disableTimeslot);
    }

    function* enableTimeslot({ data }) {
        let error = 'Something went wrong.';
        const enableRes = yield call(api.enableTimeslot, data.id);

        if (enableRes.ok) {
            const success = 'Great Things Happening.';
            return yield put(Actions.enableTimeslotSuccess(enableRes.data.data, success));
        }
        error = path(['data', 'error_msg'], enableRes) || error;
        yield put(Actions.enableTimeslotFailed(error));
    }

    function* watchEnableTimeslot() {
        yield takeLatest(Types.ENABLE_TIMESLOT_REQUEST, enableTimeslot);
    }

    return {
        getTimeslots,
        watchTimeslots,
        watchCreateTimeslot,
        watchRemoveTimeslot,
        watchDisableTimeslot,
        watchEnableTimeslot,
    };
};
